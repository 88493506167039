import React from 'react'
import { Button, Seo } from '../../../components/common'
import { AuthFlowWrapper } from '../../../components/views/layout'
import { Paths } from '../../../constants/structure'

import * as styles from './trial.module.scss'

const TrialConfirmationPage = () => (
   <AuthFlowWrapper
      heading={`Thanks! Your Trial account is confirmed. Please click below to open the app and Sign In.`}
   >
      <Seo title="Login" />
      <div className={styles.buttonWrapper}>
         <Button
            minWidth="100%"
            type="link"
            variant="secondary"
            href={Paths.LOGIN}
         >
            Sign In
         </Button>
      </div>
   </AuthFlowWrapper>
)

export default TrialConfirmationPage
